<template>
  <div class="container">

    <div class="content">
      <div class="operation">
        <el-button type="primary" icon="el-icon-plus" @click="handleCreate">新建</el-button>
        <el-popconfirm
          style="margin-left: 20px;"
          @confirm="batchDelete"
          title="确定删除类目吗？"
        >
          <el-button type="danger" plain :disabled="selected.length === 0" slot="reference">删除</el-button>
        </el-popconfirm>
      </div>
      <el-table
        ref="multipleTable"
        :data="categories"
        style="width: 100%"
        row-key="id"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          prop="name"
          label="类目名称"
        >
        </el-table-column>
        <el-table-column
          prop="level"
          min-width="160"
          label="级别"
        >
        </el-table-column>
        <el-table-column
          prop="imgUrl"
          min-width="160"
          label="图片"
        >
          <template slot-scope="scope">
            <div class="image">
              <img v-if="scope.row.mainPic" :src="scope.row.mainPic" alt=""/>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="enable"
          label="展示"
        >
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.enable"
              @change="v => handleStatusChange(v, scope.row.id)"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="lineNumber"
          label="每行展示个数"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          align="center"
          prop="operation"
          min-width="100"
          label="操作"
        >
          <template slot-scope="scope">
            <div>
              <el-button type="text" @click="edit(scope.row)">编辑</el-button>
              <el-button type="text" @click="addChildren(scope.row)" v-show="scope.row.level === 1">
                新增下级
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageOptions"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>

    <EditContent
      :visible="visible"
      :type="type"
      :data="selectData"
      :children="children"
      @onConfirmEdit="handleConfirmEdit"
      @onConfirmCreate="handleConfirmCreate"
      @onCancel="visible = false"
    />

  </div>
</template>

<script>
import { pageOptions } from '@/utils/config'
import * as R from 'ramda'
import * as categoryService from '@/api/category'
import EditContent from './edit'

export default {
  components: {
    EditContent
  },
  data () {
    return {
      categories: [],
      selected: [],
      total: 10,
      pageOptions: pageOptions,
      pageSize: 10,
      currentPage: 1,
      visible: false,
      type: 'create',
      children: false,
      selectData: null
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData (page = 1, size = 10) {
      const res = await categoryService.list({ page: page - 1, size })
      this.categories = res.list
      this.total = res.totalElements
    },
    handleCreate () {
      this.type = 'create'
      this.visible = true
      this.children = false
    },
    edit (data) {
      this.selectData = R.clone(data)
      if (data.level === 2) {
        const parent = this.categories.reduce((a, c) => {
          const target = c.children.find(o => o.id === data.id)
          if (target) {
            a = c
          }
          return a
        }, null)
        this.selectData = Object.assign(this.selectData, { parentName: parent.name, parentId: parent.id })
      }
      this.children = data.level !== 1
      this.type = 'edit'
      this.visible = true
    },
    handleSelectionChange (val) {
      this.selected = val
    },
    addChildren (data) {
      this.selectData = { parentName: data.name, level: 2, id: data.id }
      this.type = 'create'
      this.visible = true
      this.children = true
    },
    async handleStatusChange (val, id) {
      try {
        await categoryService.updateStatus(id)
        this.$notify.success({ title: '更新成功！' })
      } catch (err) {
        this.$notify.error({ title: '更新失败！' })
      } finally {
        this.fetchData()
      }
    },
    async deleteOne (id) {
      try {
        await categoryService.deleteById(id)
        // this.$notify.success({ title: '删除类目成功' })
        this.fetchData(this.currentPage, this.pageSize)
      } catch (err) {
        // this.$notify.error({ title: '删除类目发生错误' })
      }
    },
    async batchDelete () {
      try {
        const selected = this.selected
        await Promise.all(selected.map(o => this.deleteOne(o.id)))
        this.$notify.success({ title: '删除类目成功' })
        this.fetchData()
      } catch (err) {
        this.$notify.error({ title: '删除类目发生错误' })
      }
    },
    async handleConfirmEdit (data) {
      console.log('data', data)
      try {
        await categoryService.update(this.selectData.id, data)
        this.visible = false
        this.$notify.success({ title: '编辑类目成功' })
        this.fetchData()
      } catch (err) {
        this.$notify.error({ title: '编辑类目发生错误' })
      }
    },
    async handleConfirmCreate (data) {
      try {
        await categoryService.create(data)
        this.visible = false
        this.$notify.success({ title: '创建类目成功' })
        this.fetchData()
      } catch (err) {
        this.$notify.error({ title: '创建类目失败' })
      }
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.fetchData(this.currentPage, val)
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.fetchData(val, this.pageSize)
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  text-align: left;
}

.content {
  .operation {
    padding: 20px;
  }
  margin-top: 10px;
  background: #FFFFFF;
  padding: 10px;
}

.image{
  &>img {
    width: 60px;
    height: 60px;
  }

}
.pagination {
  margin-top: 30px;
  text-align: center;
}

</style>
