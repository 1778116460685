<template>
  <el-dialog
    :title="type === 'create' ? '新建' : '编辑'"
    :visible.sync="visible"
    :append-to-body="true"
    :before-close="cancel"
    width="600px"
  >
    <field label="一级类目：" :asterisk="true" labelWidth="110px" :validation="validation.name" v-show="children">
      <el-input v-model="formData.name" placeholder="请输入类目名称" disabled></el-input>
    </field>
    <field label="类目名称：" :asterisk="true" labelWidth="110px" :validation="validation.name" v-show="!children">
      <el-input v-model="formData.name" placeholder="请输入类目名称"></el-input>
    </field>
    <field label="类目名称：" :asterisk="true" labelWidth="110px" :validation="validation.childrenName" v-show="children">
      <el-input v-model="formData.childrenName" placeholder="请输入类目名称"></el-input>
    </field>
    <field label="类目主图：" :asterisk="true" labelWidth="110px" :validation="validation.mainPic" v>
      <el-upload
        accept="image/*"
        :on-preview="handlePictureCardPreview"
        :action="baseUrl"
        :headers="headers"
        :show-file-list="false"
        :on-success="handleAvatarSuccess"
      >
        <img v-if="formData.mainPic" :src="formData.mainPic" class="avatar">
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
    </field>
    <!-- <field label="手机号码：" :asterisk="true" labelWidth="81px" :validation="validation.login" >
      <el-input v-model="formData.login" placeholder="请输入手机号码"></el-input>
    </field> -->
    <field label="展示状态：" :asterisk="true" labelWidth="110px">
      <el-radio-group v-model="formData.enable">
        <el-radio :label="1">显示</el-radio>
        <el-radio :label="2">隐藏</el-radio>
      </el-radio-group>
    </field>
    <field label="每行展示个数：" :asterisk="true" labelWidth="110px" :validation="validation.lineNumber">
      <el-input-number v-model="formData.lineNumber" :controls="false" :min="0" style="width: 100px"></el-input-number>
    </field>
    <field label="类目排序：" :asterisk="true" labelWidth="110px">
      <el-input-number v-model="formData.seqencing" :controls="false" :min="0" style="width: 100px"></el-input-number>
    </field>
    <div class="footer">
      <el-button type="primary" @click="confirm">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as v from '@/utils/validator'
import validation from '@/mixins/validation'
import { baseURL } from '@/config.js'
import * as R from 'ramda'
const init = {
  name: '',
  childrenName: '',
  mainPic: null,
  enable: 1,
  lineNumber: 0,
  seqencing: 0
}

export default {
  naem: 'editRole',
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    children: {
      default: false,
      type: Boolean
    },
    type: {
      type: String,
      default: 'create'
    },
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      baseUrl: `${baseURL}/api/file/v1/ajh`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token') || ''}`
      },
      formData: R.clone(init)
    }
  },
  mixins: [validation({
    rules: {
      name () { return this.nameRule },
      childrenName () { return this.childrenNameRule },
      lineNumber () { return this.lineNumberRule },
      mainPic: [v.required('请选择类目主图')]
    },
    field: 'formData'
  })],
  computed: {
    lineNumberRule () {
      return this.type && this.type === 'create' ? [v.required('请输入每行展示个数')] : []
    },
    nameRule () {
      return this.children ? [] : [v.required('请输入一级类目名称')]
    },
    childrenNameRule () {
      return this.children ? [v.required('请输入二级类目名称')] : []
    }
  },
  methods: {
    handleAvatarSuccess (res, file) {
      this.formData.mainPic = res.body.data.url
    },
    cancel () {
      this.$emit('onCancel')
      this.formData = R.clone(init)
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    async confirm () {
      await this.$validate()

      // 创建第一级
      if (this.type === 'create' && !this.children) {
        const postData = R.clone(this.formData)
        postData.enable = this.formData.enable === 1
        this.$emit('onConfirmCreate', postData)
      }
      // 编辑第一级
      if (this.type === 'edit' && !this.children) {
        const postData = R.clone(this.formData)
        postData.enable = this.formData.enable === 1
        this.$emit('onConfirmEdit', postData)
      }
      // 创建第二级
      if (this.type === 'create' && this.children) {
        const postData = {
          name: this.formData.childrenName,
          mainPic: this.formData.mainPic,
          enable: this.formData.enable === 1,
          lineNumber: this.formData.lineNumber,
          seqencing: this.formData.seqencing,
          parentId: this.data.id
        }

        this.$emit('onConfirmCreate', postData)
      }
      // 编辑第二级
      if (this.type === 'edit' && this.children) {
        const postData = {
          name: this.formData.childrenName,
          mainPic: this.formData.mainPic,
          enable: this.formData.enable === 1,
          lineNumber: this.formData.lineNumber,
          seqencing: this.formData.seqencing,
          parentId: this.data.parentId
        }
        this.$emit('onConfirmEdit', postData)
      }
      this.formData = R.clone(init)
      this.$emit('onCancel')
    }
  },
  watch: {
    data: {
      deep: true,
      handler: function (curVal, oldVal) {
        if (!R.isNil(curVal)) {
          this.formData = {
            name: curVal.level === 1 ? curVal.name : curVal.parentName,
            childrenName: curVal.level === 1 ? '' : curVal.name,
            mainPic: curVal.mainPic,
            enable: curVal.enable ? 1 : 2,
            lineNumber: curVal.lineNumber,
            seqencing: curVal.seqencing
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  border-top: 1px solid #D9E0F0FF;
  padding-top: 30px;
  text-align: center;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}

</style>
